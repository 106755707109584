h4, h5, h6 {
    color: #fff !important;
}

text {
    fill: #fff !important;
}

.list-group div {
    color: #fff !important;
}


.dell-apex-link-tag {
    color: #9da9bb !important;
}

canvas+div ul li {
    text-decoration: none !important;
    outline: none !important;
    user-select: none;
    /* Prevent selection */
}

canvas+div ul li:focus {
    text-decoration: none !important;
    outline: none !important;
}